import React from "react"
import { graphql } from "gatsby"
import Apps from "../components/apps"
import AppHead from "../components/apps/head"
import Section from "../components/layouts/section"
import Grid from "../components/layouts/grid"
import Column from "../components/layouts/column"
import Hero from "../components/elements/hero"
import Heading from "../components/elements/heading"
import Card from "../components/elements/card"

export default ({ data, location }) => {
  const magazines = data.wpgraphql.appOptions.global.magazines
  return (
    <Apps>
      <AppHead pagePath={location.pathname} />
      <Section padding="md">
        <Hero />
      </Section>
      <Section padding="md">
        <Heading level="2" size="lg" color="black" en={true}>
          Magazine
        </Heading>
        <Grid media="mobile" min="240px" max="1fr" gap="24px">
          {magazines.map((magazine, i) => (
            <Column key={i}>
              <Card
                cover={magazine.cover}
                url={magazine.url}
                title={magazine.title}
                description={magazine.description}
                coverHeight="sm"
              />
            </Column>
          ))}
        </Grid>
      </Section>
    </Apps>
  )
}

export const query = graphql`
  query PageHome {
    wpgraphql {
      appOptions {
        global {
          magazines {
            cover {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            url
            title
            description
          }
        }
      }
    }
  }
`
