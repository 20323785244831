import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Logo from "../../../assets/svg/logo.svg"
import "./index.css"

export default () => {
  const min = 1
  const max = 2
  let count = Math.floor(Math.random() * (max + 1 - min)) + min
  return (
    <StaticQuery
      query={graphql`
        query HeroQuery {
          mainoko: file(name: { eq: "mainoko" }) {
            childImageSharp {
              fluid(maxWidth: 40) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          qrac: file(name: { eq: "qrac" }) {
            childImageSharp {
              fluid(maxWidth: 40) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          maro: file(name: { eq: "maro" }) {
            childImageSharp {
              fluid(maxWidth: 40) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          light: file(name: { eq: "cover-light" }) {
            childImageSharp {
              fluid(maxWidth: 952) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          dark: file(name: { eq: "cover-dark" }) {
            childImageSharp {
              fluid(maxWidth: 952) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="hero">
          <div className="hero-front">
            <Logo className="hero-logo" />
            <div className="hero-faces">
              <Image
                className="hero-face-image"
                fluid={data.mainoko.childImageSharp.fluid}
                alt="Mainoko"
              />
              <Image
                className="hero-face-image"
                fluid={data.qrac.childImageSharp.fluid}
                alt="Mainoko"
              />
              <Image
                className="hero-face-image"
                fluid={data.maro.childImageSharp.fluid}
                alt="Mainoko"
              />
            </div>
            {/*<Link to="/about/" className="hero-button">
              ABOUT SITE
            </Link>*/}
          </div>
          <div className="hero-back">
            {(() => {
              if (count === 1) {
                return (
                  <Image
                    className="hero-cover-image"
                    fluid={data.light.childImageSharp.fluid}
                    alt="Hero Image Light"
                  />
                )
              } else {
                return (
                  <Image
                    className="hero-cover-image"
                    fluid={data.dark.childImageSharp.fluid}
                    alt="Hero Image Dark"
                  />
                )
              }
            })()}
          </div>
        </div>
      )}
    />
  )
}
