import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import classNames from "classnames"
import "./index.css"

export default ({ cover, url, title, description, coverHeight }) => {
  const coverHeightClass = coverHeight ? "is-height-" + coverHeight : ""
  const cardCoverBoxClasses = classNames("card-cover-box", coverHeightClass)
  return (
    <StaticQuery
      query={graphql`
        query CardQuery {
          noimage: file(name: { eq: "noimage" }) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const noimage = data.noimage.childImageSharp.fluid
        return (
          <Link className="card" to={url}>
            <div className={cardCoverBoxClasses}>
              <div className="card-cover-wrap">
                {cover && cover.localFile ? (
                  <Image
                    className={`card-cover`}
                    fluid={cover.localFile.childImageSharp.fluid}
                  />
                ) : (
                  <Image className={`card-cover`} fluid={noimage} />
                )}
              </div>
            </div>
            <div className="card-meta-box">
              <p className="card-title">{title}</p>
              {description && <p className="card-description">{description}</p>}
            </div>
          </Link>
        )
      }}
    />
  )
}
